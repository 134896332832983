
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarSales from "@/components/sidebar-sales.vue"; // @ is an alias to /src
import SaleService from "@/services/sale-service";
@Component({
  components: {
    Header,
    SidebarSales,
  },
})
export default class GenerateInvoice extends Vue {
  project = null;
  invoice_type = null;
  trans: any = {};
  loader = false;
  option_invoices = ["Final Payment", "Progress", "Other"];

  option_covered_period: any = [];
  items = [
    {
      sno: 1,
      item: "",
      price: 0,
      quantity: 0,
      amount: 0,
      unit: "SAR",
    },
  ];
  public contract_start = "";
  public contract_end = "";
  public invoice_start = "";
  public invoice_end = "";
  public payment_due = "";
  public no_retention = "0";

  changedValue() {}
  change_project() {}
  changedValueInvoice() {}
  onchange(index: any, value: any) {
    this.items[index].amount =
      Math.round((value.quantity * value.price + Number.EPSILON) * 100) / 100;
  }
  saveinvoice() {
    this.loader = true;
    this.trans["contract_start"] = this.contract_start;
    this.trans["contract_end"] = this.contract_end;
    this.trans["invoice_start"] = this.invoice_start;
    this.trans["invoice_end"] = this.invoice_end;
    this.trans["no_retention"] = this.no_retention;
    this.trans["payment_due"] = this.payment_due;

    this.trans["project"] = this.project;
    this.trans["invoice_type"] = this.invoice_type;
    let obj = {
      invoice: this.trans,
      items: this.items,
    };
    // SaleService.create_internal_invoice(obj).then((response) => {
    //     let data = response.data;
    //     this.loader = false;
    //     this.$router.push({ path: `/internal/invoice/${data.invoice}` });
    // }).catch((e) => {
    //     this.loader = false;
    //     console.log(e);
    // });
  }
  add_item() {
    this.items.push({
      sno: this.items.length + 1,
      item: "",
      price: 0,
      quantity: 0,
      amount: 0,
      unit: "SAR",
    });
  }
  mounted() {}
  deleteRow(index:any) {
    this.items.splice(index, 1);
  }
}
